import React from 'react';
import {
  FormLabel as CFormLabel,
  type FormLabelProps as CFormLabelProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { Text } from '../text';

export interface FormLabelProps extends CFormLabelProps {
  isInvalid?: boolean;
  /**
   * This prop is read from the `FormControl` context but can be passed as well.
   * If passed, it'll override the context and give the `label` look disabled
   */
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const FormLabel = React.forwardRef<HTMLLabelElement, FormLabelProps>(
  ({ children, className, ...props }, ref) => (
    <CFormLabel
      ref={ref}
      mb={1}
      {...props}
      className={cx('forge-label', 'forge-label--reset', className)}
    >
      <Text variant="bold" as="span">
        {children}
      </Text>
    </CFormLabel>
  )
);
