import { css } from '@emotion/react';
import { SystemStyleObjectRecord } from '@chakra-ui/react';
import { useTheme } from '../theme';

export const defaultStyleCss = {
  pagination: {
    display: 'inline-flex',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    userSelect: 'none',
  },
  paginationDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paginationPrev: {},
  paginationNext: {},
  paginationPrevAndNext: {
    cursor: 'pointer',
    display: 'inline-flex',
    borderColor: 'transparent',
    alignItems: 'center',
  },
  paginationJumpPrev: {},
  paginationJumpNext: {},
  paginationJumpPrevAndNext: {
    display: 'inline-flex',
    position: 'relative',
    cursor: 'default',
  },
  paginationJumpPrevAndNextButton: {
    background: 'transparent',
    border: 'none',

    '&:after': {
      display: 'block',
      content: '"..."',
    },
  },
  paginationItem: {
    cursor: 'pointer',
    display: 'inline-flex',
    borderColor: 'transparent',
    height: '40px',
  },
  paginationItemAnchor: {},
  paginationItemActive: {},
} as const;

export type CustomPaginationStyle = Partial<
  Record<keyof typeof defaultStyleCss, SystemStyleObjectRecord>
>;

/**
 * @desc internal use - customize rc-pagination with forge-classnames.
 * @returns {SerializedStyles} emotion css styles
 */
export const usePaginationStyles = (custom?: CustomPaginationStyle) => {
  const { forgeTokens } = useTheme();
  const textColor = forgeTokens.colors.text;
  const opacityDisabled = forgeTokens.opacities.disabled;

  const bodyBold = {
    fontFamily: forgeTokens.fonts.body,
    fontSize: forgeTokens.fontSizes.bodyBold,
    fontWeight: forgeTokens.fontWeights.bodyBold,
    lineHeight: forgeTokens.lineHeights.bodyBold,
    letterSpacing: forgeTokens.letterSpacings.bodyBold,
  };

  const defaultStyles = css({
    '& .forge-pagination': {
      ...defaultStyleCss.pagination,
      gap: forgeTokens.space[2],
      ...custom?.pagination,

      '&-disabled': {
        ...defaultStyleCss.paginationDisabled,
        color: textColor,
        opacity: opacityDisabled,
        ...custom?.paginationDisabled,
      },
      // previous and next icons
      '&-prev, &-next': {
        ...defaultStyleCss.paginationPrevAndNext,
        ...custom?.paginationPrevAndNext,
      },
      '&-prev': {
        ...defaultStyleCss.paginationPrev,
        ...custom?.paginationPrev,
      },
      '&-next': {
        ...defaultStyleCss.paginationNext,
        ...custom?.paginationNext,
      },
      // pagination items
      '&-item': {
        ...defaultStyleCss.paginationItem,
        borderBottomWidth: forgeTokens.borderWidths.activeUnderline,
        padding: forgeTokens.space[2],
        ...custom?.paginationItem,

        '& a': {
          ...defaultStyleCss.paginationItemAnchor,
          color: textColor,
          ...custom?.paginationItemAnchor,
        },
        '&-active': {
          ...defaultStyleCss.paginationItemActive,
          ...bodyBold,
          borderBottom: forgeTokens.borders.activeUnderline,
          ...custom?.paginationItemActive,
        },
        '&-disabled': {
          ...defaultStyleCss.paginationDisabled,
          color: textColor,
          opacity: opacityDisabled,
          ...custom?.paginationDisabled,
        },
      },
      // three dots jumpers
      '&-jump-prev, &-jump-next': {
        ...defaultStyleCss.paginationJumpPrevAndNext,
        color: textColor,
        opacity: opacityDisabled,
        padding: forgeTokens.space[2],
        ...custom?.paginationJumpPrevAndNext,

        button: {
          ...defaultStyleCss.paginationJumpPrevAndNextButton,
          color: textColor,
          ...custom?.paginationJumpPrevAndNextButton,
        },
      },
      '&-jump-prev': {
        ...defaultStyleCss.paginationJumpPrev,
        ...custom?.paginationJumpPrev,
      },
      '&-jump-next': {
        ...defaultStyleCss.paginationJumpNext,
        ...custom?.paginationJumpNext,
      },
    },
  });

  return defaultStyles;
};
