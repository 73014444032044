import { Collapse as ChakraCollapse } from '@chakra-ui/react';
import styled from '@emotion/styled';

/**
 * @see https://github.com/chakra-ui/chakra-ui/issues/2966
 */
export const Collapse = styled(ChakraCollapse)`
  overflow: ${({ in: isEnabled }) =>
    isEnabled ? 'initial !important' : 'hidden'};
`;

export { type CollapseProps } from '@chakra-ui/react';
